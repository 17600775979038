import { useEffect } from 'react';

export default function useClickOutsideHandler(ref, stateFunction) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && !event.target.className.includes('material-icons')) {
        stateFunction();
      }
    }

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref]);
}

import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import logo from '../../images/logo.png';
import fundation from '../../images/fundacja.jpg';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';

const Menu = ({ isFestival }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [isMounted, setIsMounted] = useState(false);

  const navRef = React.createRef();
  useClickOutsideHandler(navRef, () => setIsCollapsed(false));

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <nav ref={navRef} className={`${isFestival ? 'isFestival' : ''} nav-menu`}>
      {!isFestival && <img src={isFestival ? fundation : logo} alt="logo" className="nav-brand" />}
      <div className="menu-button }">
        <button onClick={() => setIsCollapsed(!isCollapsed)} type="button">
          <span className="material-icons">{isCollapsed ? 'close' : 'menu'}</span>
        </button>
      </div>
      <ul className={`nav-links ${isCollapsed ? 'collapsed' : ''}`}>
        <li>
          <Link activeClassName="nav-link--active" className="nav-link" to="/">
            Strona Główna
          </Link>
        </li>
        <li>
          <Link activeClassName="nav-link--active" className="nav-link" to="/news">
            Aktualności
          </Link>
        </li>
        <li>
          <Link activeClassName="nav-link--active" className="nav-link" to="/gallery">
            Galeria
          </Link>
        </li>
        <li>
          <Link activeClassName="nav-link--active" className="nav-link" to="/schedule">
            Kurs
          </Link>
        </li>
        <li>
          <Link activeClassName="nav-link--active" className="nav-link" to="/festival">
            Festiwal
          </Link>
        </li>
        <li>
          <Link activeClassName="nav-link--active" className="nav-link" to="/contact">
            Kontakt
          </Link>
        </li>
        <li>
          <a
            className="nav-link"
            to={
              isFestival
                ? 'https://www.facebook.com/DiasDeTangoLodz'
                : 'https://www.facebook.com/tangolodzosrodekkultury'
            }
            target="blank"
          >
            <span className="material-icons">facebook</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;

Menu.propTypes = {
  isFestival: PropTypes.bool,
};

Menu.defaultProps = {
  isFestival: false,
};

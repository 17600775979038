/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './Header';

const Layout = ({ children, isFestival }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header isFestival={isFestival} siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <footer className={isFestival ? 'isFestival' : ''}>
          Powered by:
          <a href="https://bambit.com.pl/">
            <span> Bambit</span>
          </a>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
